/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-10 14:46:26
 * @FilePath: /apiForManage/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const flowApi = {
  updateLimitLog: '/user/capital/updateLimitLog',
  updateLimit: '/user/capital/updateLimit'
}
export function updateLimitLog (data = {}) {
  return request({
    url: flowApi.updateLimitLog,
    method: 'post',
    data
  })
}
export function updateLimit (data = {}) {
  return request({
    url: flowApi.updateLimit,
    method: 'post',
    data
  })
}
