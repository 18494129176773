<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-09 15:03:00
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:28:52
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/Quota/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-spin :spinning="isLoading">
    <div class="quota-container">
      <GoBack @goBack="goBack" title1="流量管理" :title2="`${title}开放供应商`" title3="额度管理" />
      <div class="all-quota-wrapper">
        <span>当前账户可用余额：{{ numFormat(residueAmount, 3, '') }}{{ moneyType }}</span>
      </div>
      <div class="contral-wrapper">
        <div class="prop-item-box">
          <div class="prop-label">流量名称</div>
          <div class="prop-input">
            <a-input class="input" v-model.trim="publisherName" disabled></a-input>
          </div>
        </div>
        <div class="prop-item-box">
          <div class="prop-label">总限额</div>
          <div class="prop-input">
            <a-input class="input" type="number" placeholder="不限" v-model.trim="aggregateLimit" :disabled="!isEdit">
              <span slot="suffix">{{ moneyType }}</span>
            </a-input>
          </div>
          <a-button type="cust" @click="handleChangeIsEdit">{{ isEdit ? '取消':'编辑' }}</a-button>
        </div>
        <div class="prop-item-box">
          <div class="prop-label">日限额</div>
          <div class="prop-input">
            <a-input class="input" type="number" placeholder="不限" v-model.trim="dayLimit" :disabled="!isEdit">
              <span slot="suffix">{{ moneyType }}</span>
            </a-input>
          </div>
          <a-button type="cust" @click="handleSave" :disabled="!isEdit">保存</a-button>
        </div>
      </div>
      <div class="table-wrapper">
        <el-table :data="dataList">
          <!-- 修改时间 -->
          <el-table-column prop="createTime" label="修改时间" min-width="200">
          </el-table-column>
          <!-- 修改内容 -->
          <el-table-column prop="type" label="修改内容" min-width="200">
            <template slot-scope="{ row }">
              {{ +row.type === 1 ? '总限额' : '日限额' }}
            </template>
          </el-table-column>
          <!-- 修改前 -->
          <el-table-column prop="oldValue" label="修改前" min-width="200">
            <template slot-scope="{ row }">
              {{ typeof row.oldValue === 'number' ? numFormat(row.oldValue, 3, '') : '不限' }}
            </template>
          </el-table-column>
          <!-- 修改后 -->
          <el-table-column prop="newValue" label="修改后" min-width="200">
            <template slot-scope="{ row }">
              {{ typeof row.newValue === 'number' ? numFormat(row.newValue, 3, '') : '不限' }}
            </template>
          </el-table-column>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="dataList.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
        <m-empty v-else style="height: 341px" imgHeight="176px">
          <template #description>暂无数据 </template>
        </m-empty>
      </div>
    </div>
  </a-spin>
</template>

<script>
import GoBack from '@/components/common/GoBack'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import { updateLimitLog, updateLimit } from '@/apiForManage/flow/quota'
export default {
  name: 'Quota',
  components: { GoBack },
  data () {
    return {
      isEdit: false,
      isLoading: false,
      // 可用余额
      residueAmount: 0,
      // 总限额
      aggregateLimit: undefined,
      oldAggregateLimit: undefined,
      // 日限额
      dayLimit: undefined,
      oldDayLimit: undefined,
      // 流量名称
      publisherName: undefined,
      // 修改记录
      dataList: [],
      // 数据数量
      total: 0,
      searchquery: {
        page: 1,
        limit: 10
      }
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥'),
      currentLoginId: (state) => state.user.id
    })
  },
  created () {
    this.getDataList()
  },
  methods: {
    numFormat,
    goBack () {
      this.$router.push({
        name: 'supplier',
        params: {
          ...this.$route.params
        }
      })
    },
    // 修改编辑状态
    handleChangeIsEdit () {
      this.isEdit = !this.isEdit
      if (!this.isEdit) {
       this.aggregateLimit = this.oldAggregateLimit
       this.dayLimit = this.oldDayLimit
      }
    },
    async handleSave () {
      const query = {
        aggregateLimit: this.aggregateLimit,
        dayLimit: this.dayLimit
      }
      const resp = await updateLimit(query)
      if (resp.code === 200) {
        this.$message.success('修改成功')
        this.isEdit = false
        this.getDataList()
      }
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      try {
        const { data = {} } = await updateLimitLog(this.searchquery)
        const { list = [], total = 0, aggregateLimit = undefined, dayLimit = undefined, residueAmount = undefined, publisherName = '' } = data
        this.dataList = list
        this.total = total
        this.aggregateLimit = aggregateLimit
        this.oldAggregateLimit = aggregateLimit
        this.dayLimit = dayLimit
        this.oldDayLimit = dayLimit
        this.residueAmount = residueAmount
        this.publisherName = publisherName
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.quota-container {
  margin: calc(@mediumMargin + 62px) @mediumMargin @mediumMargin @mediumMargin;
  .all-quota-wrapper, .contral-wrapper{
    border-radius: @mediumRadius;
    box-shadow: @modelShadow;
    border: 1px solid @modelBorderColor;
    background-color: #fff;
    padding: @smallMargin;
  }
  .contral-wrapper{
    margin-top: @mediumMargin;
    display: flex;
    flex-direction: column;
    .prop-item-box{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      .prop-label{
        width: 100px;
      }
      .prop-input{
        .input{
          width: 420px;
        }
      }
      button{
        margin-left: @compMargin;
      }
    }
  }
  .table-wrapper{
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    border-radius: @mediumRadius;
    padding: 0 @smallMargin;
    margin-top: @mediumMargin;
    background-color: #fff;
    .pagination {
      margin: @smallMargin 0;
    }
  }
}
</style>
